// import member01 from "../img/team/gerardo-morales-hierro.png";
// import member02 from "../img/team/mario-san-miguel-montesdeoca.png";
// import member03 from "../img/team/daniel-mayor-duarte.png";
// import member04 from "../img/team/elena-maneiro-franco.png";
// import member05 from "../img/team/braulio.jpg";
// import member06 from "../img/team/francisco-javier--del-pino-suarez.png";
// import member07 from "../img/team/sunil-lalchand-khemchandani.png";
// import member08 from "../img/team/jose-alonso.jpg";
// import member09 from "../img/team/adolfo-nevirovski.png";
// import member10 from "../img/team/alberto-pellon.jpg";
// import memberSergio from "../img/team/sergio-mateos.jpg";
// import memberDavid from "../img/team/david-galante.jpg";
// import memberDailos from "../img/team/dailos-ramos.jpg";

const member01 = "team/gerardo-morales-hierro.png";
const member02 = "team/mario-san-miguel-montesdeoca.png";
const member03 = "team/daniel-mayor-duarte.png";
const member04 = "team/elena-maneiro-franco.png";
const member05 = "team/braulio.jpg";
const member06 = "team/francisco-javier--del-pino-suarez.png";
const member07 = "team/sunil-lalchand-khemchandani.png";
const member08 = "team/jose-alonso.jpg";
const member09 = "team/adolfo-nevirovski.png";
const member10 = "team/alberto-pellon.jpg";
const memberSergio = "team/sergio-mateos.jpg";
const memberDavid = "team/david-galante.jpg";
const memberDailos = "team/dailos-ramos.jpg";
const memberJose = "team/jose-saiz.jpg";

export const team = [
  {
    // jose-alonso
    name: "Jose Alonso",
    position:
      "Strategy Advisor. Executive President of Celestia Technologies Group. Leiden, The Netherlands.",
    image: member08,
  },
  {
    // adolfo-nevirovski
    name: "Dr. Adolfo Nemirovsky",
    position:
      "WIMMIC representative for the US and Israel. San José, California.",
    image: member09,
  },
  {
    // alberto-pellon
    name: "Alberto Pellón",
    position:
      "Advisor. Technical Director of Celestia UK.  Harwell Campus, Oxfordshire, UK.",
    image: member10,
  },
  {
    // francisco-javier--del-pino-suarez
    name: "Dr. Javier del Pino Suárez",
    position:
      "In-house Technical Advisor & Consultant. Applied Institute of Microelectronics. IUMA. University of Las Palmas de Gran Canaria",
    image: member06,
  },
  {
    // sunil-lalchand-khemchandani
    name: "Dr. Sunil Lalchand Khemchandani",
    position:
      "In-house Technical Advisor & Consultant. Applied Institute of Microelectronics. IUMA. University of Las Palmas de Gran Canaria",
    image: member07,
  },
];

export const designTeam = [
  {
    // gerardo-morales-hierro
    name: "Gerardo Morales-Hierro",
    position: "Executive Director",
    image: member01,
  },
  {
    // braulio
    name: "Braulio Quintana Sánchez",
    position: "R&D and Quality Manager. ISO 9001, ISO 16002",
    image: member05,
  },
  {
    // elena-maneiro-franco
    name: "Elena Maneiro Franco",
    position: "Scale-up Financing",
    image: member04,
  },
  {
    // mario-san-miguel-montesdeoca
    name: "Mario San Miguel Montesdeoca",
    position: "RFIC/MMIC Engineer",
    image: member02,
  },
  {
    // daniel-mayor-duarte
    name: "Daniel Mayor Duarte",
    position: "RFIC/MMIC Engineer",
    image: member03,
  },
  {
    name: "Sergio Mateos Angulo",
    position: "RFIC/MMIC Engineer",
    image: memberSergio,
  },
  {
    name: "Dr. Dailos Ramos Valido",
    position: "RFIC/MMIC Engineer",
    image: memberDailos,
  },
  {
    name: "Jose Saíz Pérez",
    position: "RFIC/MMIC Engineer",
    image: memberJose,
  },
];
