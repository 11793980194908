import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ImageHero from "../components/ImageHero";
import ContactHero from "../components/ContactHero";
import LocalImage from "../components/LocalImage";
import { team, designTeam } from "../constants/team";

export const AboutPageTemplate = ({
  title,
  childImageSharp,
  fileDataEdges,
}) => {
  return (
    <div className="page-about">
      <ImageHero
        heading={title}
        childImageSharp={childImageSharp}
        style={{ backgroundPosition: "center -100px" }}
      />
      <div className="page-about-content">
        <section>
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <div className="content">
                    <div className="columns">
                      {/* ADVISORS */}
                      <div className="column">
                        <h2 style={{ marginBottom: 50 }}>Advisors</h2>
                        <div className="columns">
                          {team
                            .slice(0, 5)
                            .map(({ image, name, position }, i) => (
                              <Fade delay={100 * i} timeout={3000} key={i}>
                                <div
                                  className="column image-team-wrapper"
                                  key={i}
                                >
                                  <LocalImage
                                    className="image-team"
                                    relativePath={image}
                                    fileDataEdges={fileDataEdges}
                                  />
                                  <div className="description-team">
                                    <p className="name">{name}</p>
                                    <p className="position">{position}</p>
                                  </div>
                                </div>
                              </Fade>
                            ))}
                        </div>
                        {/* DESIGN-TEAM */}
                        <h2 style={{ marginBottom: 50, marginTop: 50 }}>
                          Team
                        </h2>
                        <div className="columns">
                          {designTeam
                            .slice(0, 5)
                            .map(({ image, name, position }, i) => (
                              <Fade delay={100 * i} timeout={3000} key={i}>
                                <div
                                  className="column image-team-wrapper"
                                  key={i}
                                >
                                  <LocalImage
                                    className="image-team"
                                    relativePath={image}
                                    fileDataEdges={fileDataEdges}
                                  />
                                  {/* <img className="image-team" src={image} /> */}
                                  <div className="description-team">
                                    <p className="name">{name}</p>
                                    <p className="position">{position}</p>
                                  </div>
                                </div>
                              </Fade>
                            ))}
                        </div>
                        <div className="columns">
                          {designTeam
                            .slice(5, 10)
                            .map(({ image, name, position }, i) => (
                              <Fade delay={100 * i} timeout={3000} key={i}>
                                <div
                                  className="column image-team-wrapper"
                                  key={i}
                                >
                                  <LocalImage
                                    className="image-team"
                                    relativePath={image}
                                    fileDataEdges={fileDataEdges}
                                  />
                                  <div className="description-team">
                                    <p className="name">{name}</p>
                                    <p className="position">{position}</p>
                                  </div>
                                </div>
                              </Fade>
                            ))}
                        </div>

                        {/*  */}
                        <h2 style={{ marginTop: 50, marginBottom: 30 }}>Quality</h2>
                        <div className="quality">
                            <p>
                            WIMMIC is <a target="_blank" href="/pdf/7391357-bup-wireless-innovate-mmic-sl.pdf"> ISO 9001:2015 </a> certified for the Design of
                            Radiofrequency Integrated Circuits (RFIC) and
                            Monolithic Microwave Integrated Circuits (MMIC).
                            WIMMIC has also been certified as an Innovative SME
                            by the Government of Spain.
                          </p>
                            <LocalImage
                              className="pyme-innovadora"
                              relativePath='pyme_innovadora_meic.jpg'
                              fileDataEdges={fileDataEdges}
                            />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section bg-grey">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <div className="content">
                    <div className="columns">
                      <div className="column">
                        <Fade delay={100} timeout={3000}>
                          <h2>WIMMIC</h2>
                          <p>
                            Founded in 2017, Wireless Innovative MMIC (WIMMIC) is an ISO-certified fabless RF and microwave MMIC design house based in the Canary Islands, Spain and Harwell, United Kingdom.
                            We are
                            part of the{" "}
                            <a
                              href="http://celestia-tech.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Celestia Technologies Group
                            </a>
                            .
                          </p>
                          <p>
                            With strong ties to the Academia and R&amp;D through
                            the{" "}
                            <a
                              href="https://en.iuma.ulpgc.es/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Institute for Applied Microelectronics (IUMA)
                            </a>
                            , WIMMIC performs cost-effective custom designs to
                            clients' specific requirements. We have successfully
                            delivered high performance chip designs into full
                            production and we have relationships with
                            world-class MMIC foundries. We can use our
                            experience in Telecommunications, Space and Defense
                            to tackle any custom design of integrated circuits
                            in any industry that requires miniaturization of
                            electronic high-frequency systems.
                          </p>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ContactHero />
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post, fileDataEdgesParent } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        childImageSharp={post.frontmatter.image.childImageSharp}
        content={post.html}
        fileDataEdges={fileDataEdgesParent.edges}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    fileDataEdgesParent: allFile {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
