import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const LocalImage = (props) => {
  const { fileDataEdges = [], relativePath } = props;
  const {
    node: { childImageSharp },
  } = fileDataEdges.find(({ node }) => {
    return node.relativePath === relativePath;
  });
  return <Img key={relativePath} {...props} fluid={childImageSharp.fluid} />;
};

export default LocalImage;
